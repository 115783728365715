<template>
   <section class="cont">
      <el-row class="crumbs-box">
         <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>创作中心</el-breadcrumb-item>
            <el-breadcrumb-item to="/material_list">素材库</el-breadcrumb-item>
            <el-breadcrumb-item>查看详情</el-breadcrumb-item>
         </el-breadcrumb>
         <el-button  class="bg-gradient" type="primary" plain @click="goBack">返回</el-button>
      </el-row>
      <el-row class="basic-info">
         <el-row class="title"><b>查看详情</b></el-row>
         <el-row class="basic-top">
            <el-col :span="8">
               <el-row class="m-bottom-10 left-text">
                  <p><span>文件类别：</span>{{ materialObj.type | filterType }}</p>
                  <p><span>文件命名：</span>{{ materialObj.title }}</p>
                  <p><span>操作人：</span></p>
                  <p><span>文件状态：</span>{{ materialObj.status | filterStatus }}</p>
                  <p><span>上传时间：</span>{{ materialObj.createTime }}</p>
               </el-row>
            </el-col>
            <el-col :span="16" v-if="materialObj.filePath">
               <el-row class="m-bottom-10 right-img" >
                  <video v-if="materialObj.type === 'VIDEO'" :src="materialObj.filePath" controls="controls"></video>
                  <img v-else :src="materialObj.filePath" alt="">
               </el-row>
               <el-row>
                  <el-button  class="bg-gradient" type="primary" @click="handleDownload" >下载</el-button>
<!--                  <el-button type="primary" @click="handlePreview" >预览大图</el-button>-->
               </el-row>
            </el-col>
         </el-row>
      </el-row>
   </section>
</template>

<script>
export default {
  data() {
    return {
       materialObj: {
          type: '',
          title: '',
          status: '',
          createTime: '',
          filePath: '',
          fileType: ''
       }
    }
  },
  components: {},
  computed: {},
  created() {
  },
  mounted() {
      this.getMaterialDetail()
  },
  methods: {
     // 获取素材详情
     getMaterialDetail(){
        this.materialObj = JSON.parse(sessionStorage.getItem('materialObj'))
     },
     // 返回上一页
     goBack() {
       this.$router.back();
     },
     // 处理下载
     handleDownload(){
        window.open(this.materialObj.filePath)
     }
  },
   filters: {
      filterType(val){
         switch (val){
            case 'IMAGE':
               return val = '图片'
            case 'NEWS':
               return val = '图文'
            case 'VIDEO':
               return val = '视频'
         }
      },
      filterStatus(val){
         switch (val){
            case 'EFFECTIVE':
               return val = '生效'
            case 'DRAFT':
               return val = '草稿'
         }
      },
   }
}
</script>

<style scoped lang="scss">
.cont {
  height: auto
}
.title {
  border-bottom: #e5e6e6 solid 1px;
  padding: 25px 0 10px 20px;
  font-size: 16px;
}

/* 基本信息 */
.basic-info {
   background:#FFF;
   box-shadow: 0 0 8px #DCE7F9;
   padding: 0 0 20px 0;
   color: #222222;
   span { color: #9c9c9c }
  .basic-top {
     padding: 20px 100px;
     .left-text{ line-height: 42px }
     .right-img {
        width: 300px; border: solid 1px #999999;
        img{ width: 100%; display: block }
        video{ width: 100%; display: block }
     }
  }
}
</style>